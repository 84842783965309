import { useState, useEffect } from "react";
import { Typography, Card, CardHeader, CardContent, CardActions, IconButton, CardMedia } from "@mui/material";
import { IArticle } from "../../types/interfaces";
import dayjs from "dayjs";
import {
  Share as ShareIcon,
  Visibility as VisibilityIcon,
  Favorite as FavoriteIcon,
  MoreVert as MoreVertIcon,
  LocalFireDepartment,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { MoreOptionMenu } from "../menus";
import { Facebook as IconFacebook, Google as IconGoogle, Twitter as IconTwitter } from "@mui/icons-material";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  PinterestShareButton,
  RedditShareButton,
  TumblrShareButton,
  TwitterIcon,
  TwitterShareButton,
  ViberShareButton,
  WhatsappIcon,
  WhatsappShareButton
} from "react-share";

import { Alert } from "@mui/material";

// interface ExpandMoreProps extends IconButtonProps {
//   expand: boolean;
// }

// const ExpandMore = styled((props: ExpandMoreProps) => {
//   const { expand, ...other } = props;
//   return <IconButton {...other} />;
// })(({ theme, expand }) => ({
//   transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
//   marginLeft: "auto",
//   transition: theme.transitions.create("transform", {
//     duration: theme.transitions.duration.shortest,
//   }),
// }));
const openInNewTab = (url: string) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}

interface ArticleCardProps {
  article: IArticle;
}

export default function ArticleCard({ article }: ArticleCardProps) {
  const navigate = useNavigate();
  // const [expanded, setExpanded] = useState(false);

  // const handleExpandClick = () => {
  //   setExpanded(!expanded);
  // };
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };



  return (
    <Card sx={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
      
      <Typography variant="h6" mb={1} align="left" color="#1890ff" fontSize={15} paddingLeft={1}>
          {article?.Category}
      </Typography>                
      {article.featured && (
          <Alert severity="warning" icon={<LocalFireDepartment/> }>FEATURED ARTICLE</Alert>
            
         )}

      <CardHeader
        // action={
        //   <IconButton aria-label="settings">
        //     <MoreVertIcon />
        //   </IconButton>
        // }
        
        title={article.title || "Untitled Article"}
        header={dayjs(article.Category)}
        subheader={dayjs(article.dayOfTheYear).format("MMM DD")}
        onClick={() => {
          navigate(`/article/${article._id}`);
        }}
      />
      {article?.image && (
        <CardMedia
          component="img"
          width="100%"
          image={article.image}
          alt="Paella dish"
          onClick={() => {
            navigate(`/article/${article._id}`);
          }}
        />
      )}
      <CardContent sx={{ maxHeight: 200, overflow: "hidden" }}>
        <Typography variant="body2" color="text.secondary">
          {article.body}
        </Typography>
      </CardContent>
      <CardActions disableSpacing sx={{ display: "flex", justifyContent: "space-between" }}>
        <IconButton
          aria-label="add to favorites"
          size="small"
          onClick={(e: any) => {
            setAnchorEl(e.currentTarget);
          }}
        >
          <ShareIcon />
        </IconButton>
        <IconButton
          onClick={() => {
            navigate(`/article/${article._id}`);
          }}
        >
          <VisibilityIcon></VisibilityIcon>
        </IconButton>
        {/* <IconButton aria-label="share">
          <ShareIcon />
        </IconButton> */}
        {/* <ExpandMore
          size="small"
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore> */}
      </CardActions>
      {/* <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent></CardContent>
      </Collapse> */}
      <MoreOptionMenu
        anchorEl={anchorEl}
        onClose={handleMenuClose}
        isLeft={true}
        menus={[
          {
            icon:   
                        <EmailShareButton
                          url={window.location.href + `article/${article._id}`}
                          subject={"Check out this article from TodayInHistory.ai: " + article.title}
                          body={"Check out this article: " + article.title}
                          className="Demo__some-network__share-button"
                        >
                          <EmailIcon size={32} round />
                        </EmailShareButton>
  
            },
          {
          icon:   
                      <TwitterShareButton
                        url={window.location.href + `article/${article._id}`}
                        via={"todayhistoryai"}
                        hashtags={["todayInHistory", "ai","history"]}
                        title={"Check out this article from TodayInHistory.ai: " + article.title}
                        className="Demo__some-network__share-button"
                      >
                        <TwitterIcon size={32} round />
                      </TwitterShareButton>

          },
          {
            icon:   
                        <FacebookShareButton
                          url={window.location.href + `article/${article._id}`}
                          quote={"Check out this article from TodayInHistory.ai: " + article.title}
                          hashtag={"#todayInHistory"}
                          className="Demo__some-network__share-button"
                        >
                          <FacebookIcon size={32} round />
                        </FacebookShareButton>
  
            },
            {
              icon:   
                          <LinkedinShareButton
                            url={window.location.href + `article/${article._id}`}
                            title={"Check out this article from TodayInHistory.ai: " + article.title}
                            summary={article.body}
                            source="TodayInHistory.ai"
                            className="Demo__some-network__share-button"
                          >
                            <LinkedinIcon size={32} round />
                          </LinkedinShareButton>
    
              },
              {
                icon:   
                            <WhatsappShareButton
                              url={window.location.href + `article/${article._id}`}
                              title={"Check out this article from TodayInHistory.ai: " + article.title}
                              className="Demo__some-network__share-button"
                            >
                              <WhatsappIcon size={32} round />
                            </WhatsappShareButton>
      
                }
        ]}
      ></MoreOptionMenu>
    </Card>

    
  );
}
