import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";

import store from "./store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import theme from "./libs/theme";
import ReactGA from "react-ga4";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
//Initialize GA4
ReactGA.initialize("G-51YYWZ995K");

root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <CssBaseline></CssBaseline>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ThemeProvider>
  </Provider>
);

const SendAnalytics = ()=> {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(SendAnalytics);
