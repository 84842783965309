import React from "react";
import Appbar from "../../layouts/Appbar";
import PageWrapper from "../../layouts/PageWrapper";
import { Button, Box, Grid, Typography, Backdrop, IconButton } from "@mui/material";

import {
  ArrowBack as IconArrowBack,
  Facebook as IconFacebook,
  Google as IconGoogle,
  Twitter as IconTwitter,
} from "@mui/icons-material";

const openInNewTab = (url: string) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}

function PageContactUs() {
  return (
    <>
      <Appbar title={"Contact Us"}></Appbar>
      <div className="page-wrapper">
        <PageWrapper>
          <Typography variant="h5"> Contact Us </Typography>
          <Typography variant="body1">
          <p>Engage and participate! At our website, we are passionate about bringing you daily news articles about historical events, and your feedback, questions, and suggestions are invaluable to us. </p>

          <p>Whether you have a specific inquiry, want to share your thoughts on a particular article, want to join our team, or simply wish to connect with us, we are here to assist you.</p>

          <p>We greatly appreciate your participation and look forward to engaging in discussions about the captivating world of history. Let's connect and embark on this journey through time together!</p>

          </Typography>
          <Box display="flex" alignItems="center" justifyContent="center" sx={{ mt: 2 }}>
              <IconButton
                color="error"
                aria-label="Google"
                size="large"
                onClick={() => {
                  openInNewTab("mailto:business.todayinhistory@gmail.com");
                }}
              >
                <IconGoogle fontSize="inherit" />
              </IconButton>
              <IconButton
                color="primary"
                aria-label="Facebook"
                size="large"
                onClick={
                  // () => {window.location.assign("https://www.facebook.com/groups/655176086647110");}
                  () => {openInNewTab('https://www.facebook.com/groups/655176086647110')}
              }
              >
                <IconFacebook fontSize="inherit" />
              </IconButton>
              <IconButton
                color="primary"
                aria-label="Twitter"
                size="large"
                onClick={() => {
                  openInNewTab("https://twitter.com/todayhistoryai");
                }}
              >
                <IconTwitter fontSize="inherit" />
              </IconButton>
            </Box>
        </PageWrapper>
      </div>
    </>
  );
}

export default PageContactUs;
