import React from "react";
import Appbar from "../../layouts/Appbar";
import PageWrapper from "../../layouts/PageWrapper";
import { Button, Box, Grid, Typography, Backdrop, IconButton } from "@mui/material";

import {
  ArrowBack as IconArrowBack,
  Facebook as IconFacebook,
  Google as IconGoogle,
  Twitter as IconTwitter,
} from "@mui/icons-material";

const openInNewTab = (url: string) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}

function PageAbout() {
  return (
    <>
      <Appbar title={"About"}></Appbar>
      <div className="page-wrapper">
        <PageWrapper>
          <Typography variant="h5" gutterBottom={true}> About </Typography>
          <Typography variant="body1">
          <p>The team at Today in History uses AI and community input to build a news media and social networking platform. We are supported by advertisements, subscriptions and donations, and our mission is to accumulate and spread knowledge of past historical events so that we can have a better sense of the current times.</p>

          <p>We believe that to truly understand what's happening today, we must look into what happened in the past.</p>

          <p>We are dedicated to bringing you fascinating insights into the past several hundred years through our daily news articles, specially curated for each day of the year. Our mission is to ignite your curiosity and take you on a journey through time, exploring timeless topics, significant events, milestones, and personalities that have shaped our world. With a team of passionate historians and researchers, we strive to provide accurate and engaging content that both educates and entertains. </p>

          <p>Whether you are a history enthusiast, a student, or simply intrigued by the stories of the past, we invite you to join us on this captivating exploration of human history. Discover the remarkable events that occurred on this very day and uncover the rich tapestry of our collective heritage. </p>

          <p>Today In History Team</p>

          </Typography>
          <Box display="flex" alignItems="center" justifyContent="center" sx={{ mt: 2 }}>
              <IconButton
                color="error"
                aria-label="Google"
                size="large"
                onClick={() => {
                  openInNewTab("mailto:business.todayinhistory@gmail.com");
                }}
              >
                <IconGoogle fontSize="inherit" />
              </IconButton>
              <IconButton
                color="primary"
                aria-label="Facebook"
                size="large"
                onClick={() => {
                  openInNewTab("https://www.facebook.com/groups/655176086647110");
                }}
              >
                <IconFacebook fontSize="inherit" />
              </IconButton>
              <IconButton
                color="primary"
                aria-label="Twitter"
                size="large"
                onClick={() => {
                  openInNewTab("https://twitter.com/todayhistoryai");
                }}
              >
                <IconTwitter fontSize="inherit" />
              </IconButton>
            </Box>
        </PageWrapper>
      </div>
    </>
  );
}

export default PageAbout;
