import { useEffect, useState } from "react";
import Appbar from "../../layouts/Appbar";
import PageWrapper from "../../layouts/PageWrapper";
import { useAppDispatch, useAppSelector } from "../../hooks/store";
import { Button, Box, Grid, Typography, Backdrop, IconButton } from "@mui/material";
import {
  ArrowBack as IconArrowBack,
  Facebook as IconFacebook,
  Google as IconGoogle,
  Twitter as IconTwitter,
} from "@mui/icons-material";
import ArticleCard from "../../components/cards/ArticleCard";
import { Route, useNavigate, useParams } from "react-router-dom";
import { apiGetArticle } from "../../api/articles";
import { IArticle } from "../../types/interfaces";
import CirclesLoader from "../../components/loader/CirclesLoader";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  PinterestShareButton,
  RedditShareButton,
  TumblrShareButton,
  TwitterIcon,
  TwitterShareButton,
  ViberShareButton,
  WhatsappIcon,
  WhatsappShareButton
} from "react-share";

const openInNewTab = (url: string) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}

interface IPageArticleProps {}

const PageArticle = ({}: IPageArticleProps) => {
  const navigate = useNavigate();
  const { article_id } = useParams();
  const [isLoading, setLoading] = useState(true);
  const [article, setArticle] = useState<IArticle | null>();

  useEffect(() => {
    if (article_id) {
      apiGetArticle(article_id)
        .then((article: IArticle) => {
          setArticle(article);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, []);

  return (
    <>
      <Appbar title={"Article"}></Appbar>
      <div className="page-wrapper">
        {isLoading ? (
          <Backdrop open={true} sx={{ zIndex: 9999 }}>
            <CirclesLoader></CirclesLoader>
          </Backdrop>
        ) : (
          <PageWrapper>
            <Box sx={{ mb: 2 }}>
              <Button
                onClick={() => {
                  navigate("/");
                }}
                color="primary"
                variant="contained"
                startIcon={<IconArrowBack></IconArrowBack>}
              >
                Go back
              </Button>
            </Box>
            <Typography variant="h5" mb={1} align="center">
              {article?.Category}
            </Typography>
            {article?.image && (
              <Box display="flex" alignItems="center" justifyContent="center" sx={{ my: 2 }}>
                <Box
                  component="img"
                  sx={{
                    width: {
                      xs: "100%",
                      sm: "50%",
                      md: "35%",
                    },
                    borderRadius: "10px",
                  }}
                  src={article.image}
                  alt="Paella dish"
                />
              </Box>
            )}
            <Typography variant="h5" mb={1}>
              {article?.title}
            </Typography>    
            <Typography variant="body1">{article?.body}
            </Typography>
       
            <Box display="flex" alignItems="center" justifyContent="center" sx={{ mt: 2 }}>
              
               
              <EmailShareButton
              url={window.location.href}
              subject={"Check out this article from TodayInHistory.ai: " + article?.title}
              body={"Check out this article: " + article?.title}
              className="Demo__some-network__share-button"
              >
              <EmailIcon size={32} round />
              </EmailShareButton>

              <TwitterShareButton
              url={window.location.href}
              via={"todayhistoryai"}
              hashtags={["todayInHistory", "ai","history"]}
              title={"Check out this article from TodayInHistory.ai: " + article?.title}
              className="Demo__some-network__share-button"
              >
              <TwitterIcon size={32} round />
              </TwitterShareButton>


              <FacebookShareButton
                url={window.location.href}
                quote={"Check out this article from TodayInHistory.ai: " + article?.title}
                hashtag={"#todayInHistory"}
                className="Demo__some-network__share-button"
              >
                <FacebookIcon size={32} round />
              </FacebookShareButton>


              <LinkedinShareButton
                url={window.location.href}
                title={"Check out this article from TodayInHistory.ai: " + article?.title}
                summary={article?.body}
                source="TodayInHistory.ai"
                className="Demo__some-network__share-button"
              >
                <LinkedinIcon size={32} round />
              </LinkedinShareButton>


                <WhatsappShareButton
                  url={window.location.href}
                  title={"Check out this article from TodayInHistory.ai: " + article?.title}
                  className="Demo__some-network__share-button"
                >
                  <WhatsappIcon size={32} round />
                </WhatsappShareButton>
              
            </Box>
          </PageWrapper>
        )}
      </div>
    </>
  );
};

export default PageArticle;
